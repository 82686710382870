/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 466px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 51.80722891566265%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABo8F3IWND/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAAwQQEf/aAAgBAQABBQLIFpiNaLlfo3//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABAwUAAAAAAAAAAAAAAAABABEhAiAiUWH/2gAIAQEABj8CxMaCYPTwypBs/8QAGhABAAIDAQAAAAAAAAAAAAAAAREhABAxcf/aAAgBAQABPyGAmJX0TGNF0kY1SfNHXX//2gAMAwEAAgADAAAAELzv/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAEDAQE/EHB//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxCRmn//xAAcEAEBAAEFAQAAAAAAAAAAAAABEQAQITFRYYH/2gAIAQEAAT8QAZES+jenwMXEiL5Od0WMxygGMcfS6AEAF5e9P//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"baltimore ravens\"\n        title=\"baltimore ravens\"\n        src=\"/static/c9cdc2828ce359f31f21c7598c9d4d20/4c59e/baltimore-ravens.jpg\"\n        srcset=\"/static/c9cdc2828ce359f31f21c7598c9d4d20/4d5fb/baltimore-ravens.jpg 166w,\n/static/c9cdc2828ce359f31f21c7598c9d4d20/558f0/baltimore-ravens.jpg 333w,\n/static/c9cdc2828ce359f31f21c7598c9d4d20/4c59e/baltimore-ravens.jpg 466w\"\n        sizes=\"(max-width: 466px) 100vw, 466px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The Baltimore Raven are one of the newer teams in the NFL.  They got their start in 1996 when Art Modell decided he would like to move his Cleveland Browns to Baltimore.  This caused some controversy but ultimately Cleveland would retain an NFL franchise as well as the name and all that went with the Brown’s established branding.  Now Modell had his team in Baltimore but needed a name.  As many team owners will often do he initiated a naming contest in which folks in the Baltimore area would submit their best idea’s for the team name.  Ultimately, the name “Ravens” was chosen due to the fact that Edgar Allan Poe, who wrote the very successful poem “The Raven”, worked in literature in Baltimore for a time and in the end he was buried in Baltimore.   The 2000 season exhibited a very strong Raven’s defense.  Their defense was, in fact, record setting with only 165 points allowed during the entire season.  They would go on to meet and defeat the Giants in Super Bowl 35 for their first Bowl win since changing names and moving from Cleveland."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
